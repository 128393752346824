<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword" @submit.native.prevent>
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				 <el-table-column type="expand">
					<template slot-scope="props">
					  <el-form label-position="left" inline class="demo-table-expand">
						<el-form-item label="商品名称">
						  <span>{{ props.row.name }}</span>
						</el-form-item>
					   <el-form-item label="地址">
						 <span>{{ props.row.address }}</span>
					   </el-form-item>
					   <el-form-item label="性质">
						 <span>{{ props.row.dw_xz }}</span>
					   </el-form-item>
					   <el-form-item label="注册资本">
						 <span>{{ props.row.reg_money + '万'}}</span>
					   </el-form-item>
						<el-form-item label="法人">
						  <span>{{ props.row.legal_person }}</span>
						</el-form-item>
					   <el-form-item label="职位">
						 <span>{{ props.row.l_duties }}</span>
					   </el-form-item>
						<el-form-item label="电话">
						  <span>{{ props.row.l_phone }}</span>
						</el-form-item>
						<el-form-item label="手机">
						  <span>{{ props.row.l_mobile }}</span>
						</el-form-item>
						<el-form-item label="传真">
						  <span>{{ props.row.l_fax }}</span>
						</el-form-item>
						<el-form-item label="邮箱">
						  <span>{{ props.row.l_email }}</span>
						</el-form-item>
						<el-form-item label="联系人">
						  <span>{{ props.row.work_person }}</span>
						</el-form-item>
					   <el-form-item label="职位">
						 <span>{{ props.row.work_duties }}</span>
					   </el-form-item>
					   <el-form-item label="电话">
						 <span>{{ props.row.work_phone }}</span>
					   </el-form-item>
					   <el-form-item label="手机">
						 <span>{{ props.row.work_mobile }}</span>
					   </el-form-item>
					   <el-form-item label="传真">
						 <span>{{ props.row.work_fax }}</span>
					   </el-form-item>
					   <el-form-item label="邮箱">
						 <span>{{ props.row.work_email }}</span>
					   </el-form-item>
					   <el-form-item label="人数">
						 <span>{{ props.row.people_num }}</span>
					   </el-form-item>
					   <el-form-item label="邮编">
						 <span>{{ props.row.zip_code }}</span>
					   </el-form-item>
					   <el-form-item label="承装等级">
						 <span>{{ props.row.cz_grade }}</span>
					   </el-form-item>
					   <el-form-item label="承修等级">
						 <span>{{ props.row.cx_grade }}</span>
					   </el-form-item>
					   <el-form-item label="承试等级">
						 <span>{{ props.row.cs_grade }}</span>
					   </el-form-item>
					   <el-form-item label="许可证编号">
						 <span>{{ props.row.cz_code }}</span>
					   </el-form-item>
					   <el-form-item label="许可证有效期">
						 <span>{{ props.row.xkz_time }}</span>
					   </el-form-item>
					   <el-form-item label="其他电力相关资质">
						 <span>{{ props.row.other_aptitude }}</span>
					   </el-form-item>
					   <el-form-item label="简介">
						 <span>{{ props.row.desc }}</span>
					   </el-form-item>
					  </el-form>
					</template>
				 </el-table-column>
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="120" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="区域" min-width="80" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.area }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="角色" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.grade_name }}
				  </template>
				</el-table-column>
				<el-table-column label="冻结原因" min-width="110" align="center" :show-overflow-tooltip="true">
				  <template slot-scope="scope">
					{{ scope.row.stop_desc }}
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="80" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="解冻" placement="top">
					  <el-button type="warning" icon="el-icon-setting" size="mini" circle @click="handleJd(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="名称" prop="username">
		      <el-input v-model="form.username" placeholder="请输入名称" type="text" clearable />
		    </el-form-item>
		    <el-form-item label="密码" prop="password">
		      <el-input v-model="form.password" placeholder="请输入密码" type="password" clearable />
		    </el-form-item>
		    <el-form-item label="电话" prop="mobile">
		      <el-input v-model="form.mobile" placeholder="请输入电话" type="text" clearable />
		    </el-form-item>
		   <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">使用</el-radio>
		      	<el-radio :label="2">停用</el-radio>
		      </el-radio-group>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
		
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'djhy',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					username:'',
					password:'',
					mobile:'',
					role_ids:[],
					status:1,
					avatar:'',
					url:'',
				},
				rules:{
					username: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
					role_ids: [
					  { required: true, message: '请选择角色', trigger: ['blur', 'change'] }
					],
				},
				operate:false,
				operateForm:{
					id:'',
					status:1,
					del_desc:''
				},
			}
		},
		mounted() {
			
		},
		methods:{
			reset() {
			  this.form = {
			   username:'',
			   password:'',
			   mobile:'',
			   role_ids:[],
			   status:1,
			   avatar:'',
			   url:'',
			  }
			  this.resetForm('form')
			},
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/user/stop_list`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.total)
			    this.list = res.data
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
			handleJd(row){
				this.$confirm('是否要解冻该信息？', '提示', {
				  confirmButtonText: '解冻',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.loading = true
				  this.axios.post('/manage/user/operate', {
					  id:row.id,
					  status:1,
					  stop_desc:''
				  }).then(res => {
				    if (res.status) {
				      this.$message({
				        message: '解冻成功',
				        type: 'success'
				      })
				      this.getList()
				    }
				    this.loading = false
				  }).catch(err => {
				    this.loading = false
				  })
				})
			},
			handleDelete(id) {
			  this.$confirm('是否要删除该信息？', '提示', {
			    confirmButtonText: '删除',
			    cancelButtonText: '取消',
			    type: 'warning'
			  }).then(() => {
			    this.loading = true
			    this.axios.delete('/manage/user/delete/' + id, {
					  params: { }
					}).then(res => {
			      if (res.status) {
			        this.$message({
			          message: '删除成功',
			          type: 'success'
			        })
			        this.getList()
			      }
			      this.loading = false
			    }).catch(err => {
			      this.loading = false
			    })
			  })
			},
		}
	}
</script>

<style>
 .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 130px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>